import $ from 'jquery';
import config from 'lib/config';
import * as Utils from 'lib/utils';
import { uncrunch } from 'graphql-crunch';

const W = window;
const $D = $(document);

W.CSRF = W.AUTH.csrf;

class GraphQL {
  constructor() {
    this.$proxy = $({});
    this.httpUri = config.api.uri + config.api.endpoint;
    this.headers = {
      'X-CLIENT-TOKEN': window.API.client,
    };

    $.Sidekick.on('captured', (evt, user) => this.setUserToken(user.token));
    $.Sidekick.on('released', evt => this.setUserToken());
  }

  on(type, fn) {
    this.$proxy.on(type, fn);
    return this;
  }

  getClientHost() {
    return this.headers['X-CLIENT-HOST'];
  }

  setClientHost(host) {
    if (host) this.headers['X-CLIENT-HOST'] = host;
    else delete this.headers['X-CLIENT-HOST'];
  }

  setUserToken(token) {
    if (token) this.headers['X-USER-TOKEN'] = token;
    else delete this.headers['X-USER-TOKEN'];
  }

  async runQuery(query, opts) {
    const headers = { ...this.headers };
    const { crunch = true, session, ...options } = opts;

    //console.log('CSRF', W.CSRF);
    headers['X-CSRF-TOKEN'] = W.CSRF;
    if (session) headers['X-SESSION-TOKEN'] = session;
    if (crunch) headers['X-CRUNCH'] = crunch;
    if (opts && opts.operationName) headers['X-OPERATION-NAME'] = opts.operationName;

    const result = await new Promise((resolve, reject) => {
      const payload = { query, ...options };

      $.ajax({
        url: this.httpUri,
        headers,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(payload),
        cache: false,
      })
        .done((data, status, xhr) => {
          const crunched = xhr.getResponseHeader('x-crunched') === 'true';

          if (crunched && data.data) data.data = uncrunch(data.data);
          if (crunched && data.extensions) data.extensions = uncrunch(data.extensions);

          data = Object.assign({}, { name: opts && opts.operationName }, data.data, data.extensions);

          if (data.csrf) W.CSRF = data.csrf;

          resolve(data);
        })
        .fail((xhr, status, err) => {
          const crunched = xhr.getResponseHeader('x-crunched') === 'true';
          let data = xhr.responseJSON;

          if (xhr.readyState === 4 && data) {
            if (crunched && data.data) data.data = uncrunch(data.data);
            if (crunched && data.extensions) data.extensions = uncrunch(data.extensions);

            data = Object.assign({}, { name: opts && opts.operationName, data: null }, data.data, data.extensions);

            if (data.csrf) W.CSRF = data.csrf;

            return resolve(data);
          }
          if (xhr.readyState === 0) err = new Error('Unable to connect to the server. Please try again.');

          //console.log(xhr, status, err);

          reject(err);
        });
    });

    return result;
  }
}

$.GraphQL = new GraphQL();
