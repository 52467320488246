import $ from 'jquery';
import config from 'lib/config';
import * as Utils from 'lib/utils';

const W = window;
const $W = $(W);
const $D = $(document);

export const WebsocketMessageType = {
  InitAck: 'init_ack',
  SubscribeAck: 'subscribe_ack',
  UnsubscribeAck: 'unsubscribe_ack',
  SyncAck: 'sync_ack',
  Publish: 'publish',
};

export const WebsocketChannelType = {
  Isolated: 'isolated',
  Successive: 'successive',
};

class WebsocketManager {
  constructor() {
    this.uri = config.api.uri.replace(/^http/, 'ws') + config.api.subscriptions.endpoint;
    this.protocol = config.api.subscriptions.protocol;
    this.retries = 0;
    this.retry_delay = 0;
    this.state = 'play';
    this.subscriptions = {
      current: new Map(),
      queue: new Map(),
    };
  }

  subscribe(channels, handler) {
    const connected = this.socket && this.socket.ready;
    const subs = this.subscriptions[connected ? 'current' : 'queue'];

    if (!channels) throw new Error(`Missing "channels" in websocket subscribe`);
    if (!Utils.isObject(channels) && !Utils.isArray(channels)) throw new Error(`Invalid "channels" in websocket subscribe`);

    for (const channel of Utils.toArray(channels)) {
      if (!Utils.isObject(channel)) throw new Error(`Invalid "channel" in websocket subscribe`);

      const { type, name } = channel;

      if (!type) throw new Error(`Missing "type" in websocket channel`);
      if (!Object.values(WebsocketChannelType).includes(type)) throw new Error(`Invalid "type" (${type}) in websocket channel`);
      if (!name) throw new Error(`Missing "name" in websocket channel`);
      if (!Utils.isString(name)) throw new Error(`Invalid "name" ${name} in websocket channel`);

      let sub = subs.get(name);

      if (!sub) {
        sub = { ack: false, type, name, messages: [], handlers: [] };
        subs.set(name, sub);
      }

      sub.handlers.push(handler);
    }

    if (connected)
      this.socket.send(
        JSON.stringify({
          type: 'subscribe',
          channels,
        }),
      );
  }

  unsubscribe(channels) {
    const connected = this.socket && this.socket.ready;
    const subs = this.subscriptions[connected ? 'current' : 'queue'];

    if (!channels) throw new Error(`Missing "channels" in websocket unsubscribe`);
    if (!Utils.isObject(channels) && !Utils.isArray(channels)) throw new Error(`Invalid "channels" in websocket unsubscribe`);

    for (const channel of Utils.toArray(channels)) {
      if (!Utils.isObject(channel)) throw new Error(`Invalid "channel" in websocket unsubscribe`);

      const { name } = channel;

      if (!name) throw new Error(`Missing "name" in websocket channel`);
      if (!Utils.isString(name)) throw new Error(`Invalid "name" ${name} in websocket channel`);

      const sub = subs[name];

      if (sub) subs.delete(name);
    }

    if (connected)
      this.socket.send(
        JSON.stringify({
          type: 'unsubscribe',
          channels,
        }),
      );
  }

  connect() {
    const host = W.sessionStorage.getItem('oas3:host') || null;
    let uri = this.uri;

    if (host) uri += '?' + Utils.toQuery({ host });

    console.groupCollapsed(`🕸 WS:${this.retries > 0 ? 'RE' : ''}CONNECTING${this.retries > 0 ? ` [ATTEMPT ${this.retries}]` : ''}`);
    console.log('URL', this.uri);
    console.log('PROTOCOL', this.protocol);
    console.groupEnd();

    this.socket = new WebSocket(uri, [this.protocol]);
    this.socket.onmessage = this.onMessage.bind(this);
    this.socket.onopen = this.onOpen.bind(this);
    this.socket.onclose = this.onClose.bind(this);
    this.socket.onerror = this.onError.bind(this);
  }

  reconnect(delay) {
    if (this.socket && this.socket.ready) {
      this.retry_delay = delay || 0;
      this.socket.close(1000);
    }
  }

  onError(evt) {
    console.groupCollapsed('🕸 WS:ERROR');
    if (evt.code) console.log('CODE', evt.code);
    if (evt.reason) console.log('REASON', evt.reason);
    console.groupEnd();
  }

  onOpen() {
    this.retries = 0;
    this.socket.send(
      JSON.stringify({
        type: 'init',
        connection: this.connection_id ? { id: this.connection_id } : undefined,
        params: {
          'X-CLIENT-TOKEN': window.API.client,
        },
      }),
    );
  }

  onClose(evt) {
    console.groupCollapsed('🕸 WS:DISCONNECTED');
    if (evt.code) console.log('CODE', evt.code);
    if (evt.reason) console.log('REASON', evt.reason);
    console.groupEnd();

    if (this.subscriptions.current.size) {
      this.subscriptions.queue = this.subscriptions.current;
      this.subscriptions.current = new Map();
    }

    clearTimeout(this.sync_timer);
    this.socket = null;
    $W.trigger('websocket:closed');

    setTimeout(() => {
      this.retry_delay = 0;
      this.retries++;
      this.connect();
    }, this.retry_delay + config.api.subscriptions.retry_timeout);
  }

  onSync() {
    clearTimeout(this.sync_timer);

    if (this.socket && this.socket.ready) {
      console.log(`🕸 WS:SYNCING`);
      this.socket.send(JSON.stringify({ type: 'sync' }));
    }

    this.sync_timer = setTimeout(this.onSync.bind(this), config.api.subscriptions.sync_timeout);
  }

  onMessage(evt) {
    const data = Utils.fromJSON(evt.data);

    if (data) {
      const { type } = data;

      if (!type) throw new Error(`Missing "type" in websocket message`);
      if (!Object.values(WebsocketMessageType).includes(type)) throw new Error(`Invalid "type" (${type}) in websocket message`);

      switch (type) {
        case WebsocketMessageType.InitAck:
          this.onInitAckMessage(data);
          $W.trigger('websocket:opened');
          break;

        case WebsocketMessageType.SubscribeAck:
          this.onSubscribeAckMessage(data);
          break;

        case WebsocketMessageType.UnsubscribeAck:
          this.onUnsubscribeAckMessage(data);
          break;

        case WebsocketMessageType.SyncAck:
          this.onSyncAckMessage(data);
          break;

        case WebsocketMessageType.Publish:
          if (this.state !== 'pause') this.onPublishMessage(data);
          break;
      }
    }
  }

  onInitAckMessage(data) {
    //console.log('onInitAckMessage', data);
    if (!data.connection) throw new Error(`Missing "connection" in websocket init ack`);
    if (!Utils.isObject(data.connection)) throw new Error(`Invalid "connection" in websocket init ack`);

    if (!data.connection.id) throw new Error(`Missing "id" in websocket init connection`);
    if (!Utils.isString(data.connection.id)) throw new Error(`Invalid "id" (${data.connection.id}) in websocket init connection`);

    const { id, host } = data.connection;

    console.groupCollapsed('🕸 WS:CONNECTED');
    console.log('ID', id);
    if (host) console.log('HOST', host);
    console.groupEnd();

    if (host) W.sessionStorage.setItem('oas3:host', host);
    this.connection_id = id;
    this.socket.ready = true;
    this.sync_timer = setTimeout(this.onSync.bind(this), config.api.subscriptions.sync_timeout);

    if (this.subscriptions.queue.size) {
      const subs = Array.from(this.subscriptions.queue.values());

      this.socket.send(
        JSON.stringify({
          type: 'subscribe',
          channels: subs.map(sub => Utils.pick(sub, ['name', 'type'])),
        }),
      );

      this.subscriptions.current = this.subscriptions.queue;
      this.subscriptions.queue = new Map();
    }
  }

  onSubscribeAckMessage(data) {
    //console.log('onSubscribeAckMessage', data);
    const { channels } = data;
    const republish = {
      type: 'republish',
      channels: [],
    };

    if (!channels) throw new Error(`Missing "channel" in websocket subscribe ack`);
    if (!Utils.isObject(channels) && !Utils.isArray(channels)) throw new Error(`Invalid "channels" in websocket subscribe ack`);

    for (const channel of Utils.toArray(channels)) {
      if (!Utils.isObject(channel)) throw new Error(`Invalid "channel" in websocket subscribe ack`);

      const { name, messages } = channel;

      if (!name) throw new Error(`Missing "name" in websocket channel`);
      if (!Utils.isString(name)) throw new Error(`Invalid "name" (${name}) in websocket channel`);

      const sub = this.subscriptions.current.get(name);

      if (sub) {
        const { type } = sub;

        if (messages && messages.length) {
          switch (type) {
            case WebsocketChannelType.Successive: {
              if (sub.messages.length) {
                const [ts1, seq1] = messages[0].split('-').map(v => Utils.toInt(v, 0));
                const [ts2, seq2] = sub.messages[0].split('-').map(v => Utils.toInt(v, 0));

                if (ts1 > ts2 || (ts1 === ts2 && seq1 > seq2)) republish.channels.push({ name, messages });
              } else sub.messages[0] = messages[0];

              break;
            }

            case WebsocketChannelType.Isolated: {
              if (window.now && sub.messages.length)
                for (let idx = sub.messages.length - 1; idx >= 0; idx--) {
                  const ts = parseInt(sub.messages[idx].split('-')[0]);

                  if (window.now - ts >= config.api.subscriptions.sync_timeout * 3) sub.messages.length--;
                }

              const missing = messages.filter(message_id => !sub.messages.includes(message_id));

              if (missing.length) republish.channels.push({ name, messages: missing });

              break;
            }
          }
        }

        sub.ack = true;
      }
    }

    if (republish.channels.length) this.socket.send(JSON.stringify(republish));
  }

  onUnsubscribeAckMessage(data) {
    //console.log('onUnsubscribeAckMessage', data);
    const { channels } = data;

    if (!channels) throw new Error(`Missing "channel" in websocket unsubscribe ack`);
    if (!Utils.isObject(channels) && !Utils.isArray(channels)) throw new Error(`Invalid "channels" in websocket unsubscribe ack`);

    for (const channel of Utils.toArray(channels)) {
      if (!Utils.isObject(channel)) throw new Error(`Invalid "channel" in websocket unsubscribe ack`);

      const { name } = channel;

      if (!name) throw new Error(`Missing "name" in websocket channel`);
      if (!Utils.isString(name)) throw new Error(`Invalid "name" (${name}) in websocket channel`);

      const sub = this.subscriptions.current.get(name);

      if (sub) {
        this.subscriptions.current.delete(name);
      }
    }
  }

  onSyncAckMessage(data) {
    //console.log('onSyncAckMessage', data);
    const { channels } = data;
    const republish = {
      type: 'republish',
      channels: [],
    };

    if (!channels) throw new Error(`Missing "channel" in websocket sync ack`);
    if (!Utils.isObject(channels) && !Utils.isArray(channels)) throw new Error(`Invalid "channels" in websocket sync ack`);

    for (const channel of Utils.toArray(channels)) {
      if (!Utils.isObject(channel)) throw new Error(`Invalid "channel" in websocket sync ack`);

      const { name, messages } = channel;

      if (!name) throw new Error(`Missing "name" in websocket channel`);
      if (!Utils.isString(name)) throw new Error(`Invalid "name" (${name}) in websocket channel`);

      const sub = this.subscriptions.current.get(name);

      if (sub) {
        const { type } = sub;

        if (messages && messages.length) {
          switch (type) {
            case WebsocketChannelType.Successive: {
              if (sub.messages.length) {
                const [ts1, seq1] = messages[0].split('-').map(v => Utils.toInt(v, 0));
                const [ts2, seq2] = sub.messages[0].split('-').map(v => Utils.toInt(v, 0));

                if (ts1 > ts2 || (ts1 === ts2 && seq1 > seq2)) republish.channels.push({ name, messages });
              } else republish.channels.push({ name, messages });

              break;
            }

            case WebsocketChannelType.Isolated: {
              if (window.now && sub.messages.length)
                for (let idx = sub.messages.length - 1; idx >= 0; idx--) {
                  const ts = parseInt(sub.messages[idx].split('-')[0]);
                  if (window.now - ts >= config.api.subscriptions.sync_timeout * 3) sub.messages.length--;
                }

              const missing = messages.filter(message_id => !sub.messages.includes(message_id));
              if (missing.length) republish.channels.push({ name, messages: missing });

              break;
            }
          }
        }
      }
    }

    if (republish.channels.length) this.socket.send(JSON.stringify(republish));
  }

  onPublishMessage(data) {
    //if (data?.channel?.name !== 'time') console.log('onPublishMessage', data);
    const { channel, payload } = data;

    if (!channel) throw new Error(`Missing "channel" in websocket publish`);
    if (!Utils.isObject(channel)) throw new Error(`Invalid "channel" in websocket publish`);

    const { name, message_id } = channel;

    if (!name) throw new Error(`Missing "name" in websocket channel`);
    if (!Utils.isString(name)) throw new Error(`Invalid "name" (${name}) in websocket channel`);
    const sub = this.subscriptions.current.get(name);

    if (sub) {
      if (message_id) {
        switch (sub.type) {
          case WebsocketChannelType.Successive: {
            if (sub.messages.length) {
              const [ts1, seq1] = message_id.split('-').map(v => Utils.toInt(v, 0));
              const [ts2, seq2] = sub.messages[0].split('-').map(v => Utils.toInt(v, 0));

              if (ts1 > ts2 || (ts1 === ts2 && seq1 > seq2)) sub.messages[0] = message_id;
              else return;
            } else sub.messages[0] = message_id;

            break;
          }

          case WebsocketChannelType.Isolated:
            if (window.now && sub.messages.length)
              for (let idx = sub.messages.length - 1; idx >= 0; idx--) {
                const ts = parseInt(sub.messages[idx].split('-')[0]);
                if (window.now - ts >= config.api.subscriptions.sync_timeout * 3) sub.messages.length--;
              }

            sub.messages.unshift(message_id);
            sub.messages.sort().reverse();
            break;
        }
      }

      for (const handler of sub.handlers) {
        handler.call(handler, name, payload, { message_id });
      }
    }
  }

  isConnected() {
    return this.socket && this.socket.ready;
  }

  isPaused() {
    return this.state !== 'play';
  }

  isSubscribed(channel) {
    const connected = this.socket && this.socket.ready;
    const subs = this.subscriptions[connected ? 'current' : 'queue'];

    return subs.has(channel);
  }
}

const manager = new WebsocketManager();

$.subscribe = (channel, handler) => {
  const self = $;

  manager.subscribe(channel, (name, data, opts) => {
    if (handler) handler.call(self, name, data, opts);
    $D.trigger('messages', [name, data, opts]);
  });

  return self;
};

$.fn.subscribe = function (channel, handler) {
  const self = this;
  const $obj = $(self);

  manager.subscribe(channel, (name, data, opts) => {
    if (handler) handler.call(self, name, data, opts);
    $obj.triggerHandler(`${name}:message`, [data, opts]);
    $obj.trigger('messages', [name, data, opts]);
  });

  return self;
};

$.unsubscribe = function (channel) {
  manager.unsubscribe(channel);
  return $;
};

$.fn.unsubscribe = function (channel) {
  manager.unsubscribe(channel);
  return this;
};

manager.connect();

export default manager;
